/* smart-calendar {
    height: auto;
    min-height: initial;
} */

.fc-direction-ltr{
    max-height: 200px !important;
    /* position: relative; */
}

.clicked-date{
    /* position: relative;
    z-index: 60000000000000; */
}


.form-container.event-form-dashboard{
    background: #c7e5ff;
    position: absolute;
    /* right: 39%; */
    z-index: 1;
    /* top: 8%; */
    width: 200px;
    padding: 10px;
}

.div:where(.swal2-container), 
h2:where(.swal2-title){
    font-size: 1.275em !important;
}


.annouce-form input,
.annouce-form textarea{
    border: 1px solid #bdbdbd;
    border-radius: 10px;
}


.fc-daygrid-day-frame {
    cursor: pointer;
}