/* .slick-arrow{
    background-color: black;

}
.slick-arrow:hover{
    background-color: black;
} */
.slick-container {
  width: 90%;
  /* background:  #FFF5EE; */
  /* background-image: url('../../public/birthday.jpeg');
    background-size: cover;
    background-repeat: no-repeat;  */
}
.slick-container .slick-item {
  padding: 10px;
}
.slick-item {
  margin: 0 3px;
  height: 300px;
}

.ravi-prakash-regular {
  font-family: "Ravi Prakash", system-ui;
  font-weight: 400;
  font-style: normal;
}

.slick-item {
  text-align: -webkit-center;
  align-content: center;
}
.slick-prev:before {
  content: "<";
  color: rgb(11, 136, 253);
  height: 10px;
  font-weight: bolder;
  font-size: 30px;
}
.slick-next:before {
  content: ">";
  color: rgb(11, 136, 253);
  height: 10px;
  font-weight: bolder;
  font-size: 30px;
}

/* .nav-link .col-md-7 img{
    width: 80%;
    height: 80%;
} */

.nav-link .col-md-7 {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .slick-item img {
    width: 81px;
    height: 81px;
    align-self: center;
    position: absolute;
    transform: translate(200%, 103px);
} */

.highlight {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  margin: 5px;
}

.slick-item {
  background-image: url("../../../public/birthday-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
}
