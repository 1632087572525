.filter-tag {
    display: inline-block;
    padding: 5px;
    background-color: #e0e0e0;
    margin-right: 5px;
    border-radius: 5px;
  }
  
  .filter-tag button {
    background: none;
    border: none;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
  }
  

  .groupby-container{
    width: 20%;
    padding: 10px;
    border-radius: 10px;
  }

  .groupby-btn{
    width: 120px;
  }


  .p-multiselect-panel, .groupby-multiSelect{
    background-color: white;
  }

  /* Add this to your CSS */
.p-multiselect .p-multiselect-panel {
  margin-top: 10px; /* Adjust as needed */
  display: block;
}


/* 
details > summary {
  padding: 4px;
  width: 100%;
  color: white;
  background-color: #746ce2;
  border: none;
  box-shadow: 1px 1px 2px #bbbbbb;
  cursor: pointer;
} */
.meet-expect {
  background: white;
  color: black;
  /* font-weight: 600; */
  /* border: 1px solid #ffab00; */
  border-radius: 5px;
  font-size: 14px;
}
.login-container {
  text-align: left;
}
.tabs-container{
  text-decoration: none;
}



