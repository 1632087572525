
.bg-productive{
    background-image: linear-gradient(to left top, #004161, #006d80, #249993, #75c59b);
}
.bg-productive-first{
    color: white;
    font-size: 1rem;
    font-weight: 500;
}
.bg-productive-heading{
    display: flex;
    justify-content: space-between;
    color: black;
}