/* Container for the grid layout */
.expense-request-modal .modal-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px; 
    gap: 20px; 
  }
  
  /* Optional: Styling for the form groups */
  .form-group {
    margin-bottom: 15px; /* Adjust spacing between form elements */
  }
  
  /* Styling for the select, textarea, and input elements */
  select, textarea, input[type="date"], input[type="number"], input[type="file"] {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  /* Optional: Add responsive design for smaller screens */
  @media (max-width: 768px) {
    .modal-body {
      grid-template-columns: 1fr; /* Stack items in a single column on small screens */
    }
  }
  

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 690px;
    margin: 30px auto;
  }
}
