.arrow-btn{
  /* background-color: rgba(82,217,153,.5); */
  /* height: 200px; */
  width: 130px;
  align-content: center;
  /* border: 0.5px solid rgba(128, 128, 128, 0.445); */
  border-radius: 10px;
  height: 28px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.check-inbtn:hover{
  background-color: rgba(128, 128, 128, 0.404) !important;
  color: #1AA7F9 !important;
}



.check-outbtn:hover{
  color: black !important;
  background-color: #ffac00 !important;
}


.arrow-btn svg{
  height: 20px;
  width: 20px;
  
}

.alert-success{
  width: 100%;
}