@import "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700;1,900";

.hrms_form {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../../../public/BG-Banner.jpg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 100vh;
}

.hrms_form_box input.form-control {
  padding: 3px 11px;
  border: none;
  background-color: #f3f4f6;
  border-radius: 8px;
  font-size: 12px;
  letter-spacing: 0.4px;
  height: 37px;
}

.hrms_form_box input.form-control:focus {
  box-shadow: none !important;
  outline: 0px !important;
  background-color: #f3f4f6;
}
button.hrms_login_btn {
  background: #006d7c;
  color: #fff;
  border: none;
  padding: 10px;
}
button.hrms_login_btn:hover {
  background: #06899b;
}

.img-fluid{
  /* margin-left: 0px !important; */
}