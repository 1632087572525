.company-container {
    padding-top: 70px;
    width: 90%;
    margin: auto;
  }
  
  .policy-category {
    margin-bottom: 20px;
    border-bottom: 1px solid #80808042;
    padding: 15px;
    border-radius: 5px;
  }
  
  .category-name {
    text-align: left;
    margin-bottom: 5px;
  }
  
  .policy-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .policy-item {
    width: 200px;
    height: 120px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    cursor: pointer;
  }
  
  .policy-name {
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .no-policies-message {
    text-align: center;
    color: #888;
  }
  
  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    width: 10%;
    max-height: 10%;
    overflow-y: auto;
  }
  
  .pdf-iframe {
    border: none;
  }
  
  .modal-actions {
    margin-top: 2px;
    display: flex;
    justify-content: center;
    gap: 2px;
  }
  
  .action-button {
    padding: 1px 2px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .open-button {
    background-color: #007bff;
  }
  
  .download-button {
    background-color: #28a745;
  }
  
  .close-button {
    background-color: red;
  }
  .policy-item-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown-men {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 5px;
    border-radius: 5px;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown-ite {
    padding: 8px 12px;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
    width: 100%;
  }
  
  .dropdown-ite:hover {
    background: #f1f1f1;
  }
  .overlay-buttons {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 5px;
    /* background: rgba(0, 0, 0, 0.7); */
    padding: 5px;
    /* border-radius: 5px; */
  }
  
  .overlay-btn {
    padding: 5px 10px;
    font-size: 10px;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .open-btn {
    background-color: #007bff;
  }
  
  .download-btn {
    background-color: #28a745;
  }
  