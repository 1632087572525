.demo-preview {
  padding-bottom: 10px;
}

.progress {
  background-color: transparent;
  border-radius: 0px;
  box-shadow: none;
}

.progress.progress-xs {
  height: 5px;
  margin-top: 5px;
}

.progress.progress-sm {
  height: 10px;
  margin-top: 5px;
}

.progress.progress-lg {
  height: 25px;
}

.progress.vertical {
  position: relative;
  width: 9px;
  height: 90px;
  display: inline-block;
  margin-right: 0px;
}

.progress.vertical > .progress-bar {
  width: 100% !important;
  position: absolute;
  bottom: 0;
}

.progress.vertical.progress-xs {
  width: 5px;
  margin-top: 5px;
}

.progress.vertical.progress-sm {
  width: 10px;
  margin-top: 5px;
}

.progress.vertical.progress-lg {
  width: 30px;
}

.progress-bar {
  background-color: #2196f3;
  box-shadow: none;
}

.progress-bar.text-left {
  text-align: left;
}

.progress-bar.text-left span {
  margin-left: 10px;
}

.progress-bar.text-right {
  text-align: right;
}

.progress-bar.text-right span {
  margin-right: 10px;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
}

.progress-bar-secondary {
  background-color: #bb05c7;
}

.progress-bar-default {
  background-color: #b0bec5;
}

.progress-bar-success {
  background-color: #64dd17;
}

.progress-bar-info {
  background-color: #29b6f6;
}

.progress-bar-warning {
  background-color: #ffd600;
}

.progress-bar-danger {
  background-color: #ef1c1c;
}

/* 2 */

.contents {
  padding: 0 10px;
  margin: 0 auto;
  background: #efefef6b;
  border: 1px solid #0000002e;
}

.contents .a-title {
  padding: 20px 10px 0 10px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000000c2;
}

.accordion-single {
  border-bottom: 1px solid #efefef;
  margin-top: 10px;
  padding: 8px 15px 25px 15px;
}

.accordion-single-item {
  border-radius: 11px;
  border: 1px solid #0000003b;
  border-top: none;
  margin: 8px 0;
}

.accordion-single-item.is-open h2 {
  border-radius: 11px 11px 0 0;
}

.accordion-single-title {
  border-top: 1px solid #efefef;
  color: white;
  padding: 20px;
  cursor: pointer;
  position: relative;
  font-size: 15px;
  margin: 0;
  background: #404040;
  border-radius: 11px;
}

.accordion-single-title::after {
  content: "";
  position: absolute;
  right: 25px;
  top: 50%;
  transition: all 0.2s ease-in-out;
  display: block;
  width: 8px;
  height: 8px;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  transform: translateY(-50%) rotate(135deg);
}

.accordion-single-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  color: black;
}

.accordion-single-content p {
  padding: 20px;
}

.accordion-single-item.is-open .accordion-single-content {
  max-height: 150px;
}

.accordion-single-item.is-open .accordion-single-title::after {
  transform: translateY(-50%) rotate(315deg);
}

/*----- carousel start ------*/
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: block !important;
}

.owl-nav .owl-prev {
  border: none !important;
  background: none !important;
  box-shadow: 1px 1px 1px transparent !important;
  font-size: 45px !important;
  position: absolute;
  top: 22%;
  left: -3.4%;
  background: #fff;
  height: 40px;
  text-align: center;
  width: 40px;
  border-radius: 40px;
  color: #009ede !important;
  padding-top: 5px;
  transition: 0.25s;
  box-shadow: 0px 10px 25px rgb(0 0 0 / 25%);
  display: none;
}

.owl-nav .owl-next {
  position: absolute;
  top: 22%;
  right: 3.4%;
  background: #fff;
  height: 40px;
  text-align: center;
  width: 40px;
  border-radius: 40px;
  color: #009ede !important;
  padding-top: 5px;
  transition: 0.25s;
  box-shadow: 0px 10px 25px rgb(0 0 0 / 25%);
  border: none !important;
  background: none !important;
  box-shadow: 1px 1px 1px transparent !important;
  font-size: 45px !important;
}

.owl-nav .owl-prev {
  font-size: 20px;
}

.owl-nav > div {
  margin-top: -10px;
  position: absolute;
  top: 150px;
  color: #009ede;
}

/* 3 */

.ep_ontime {
  display: flex;
  color: white;
  justify-content: space-between;
  padding: 16px;
  margin: 10px 0;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, 0.35);
  background: linear-gradient(90deg, #ffffff, #008cff);
  position: relative;
}

.ep_ontime th {
  text-align: center;
  color: #fff;
}

.ep_ontime th:first-child {
  text-align: left;
  width: 55%;
}

.ep_date {
  font-size: 14px;
  color: #000;
}

.ep_day {
  color: #000;
  font-weight: 500;
  font-size: 20px;
}

.ep_pday {
  display: flex;
}

.ePtitle {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.ePtime {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
}

.ep_ptime {
  font-weight: 700;
  color: #008cee;
  font-size: 12px;
  border-radius: 16px;
  background: #fff;
  margin: 17px auto;
  padding: 2px 10px;
  box-shadow: 0 1px 3px #87aff3, inset 0 0 15px rgba(255, 255, 255, 0.35);
}

.ep_etime {
  margin-left: 2rem;
}

table {
  width: 100%;
}

.status_table th,
td {
  /* font-size: 13px; */
  /* color: #464646; */
  padding: 8px;
  border: 1px solid #14b49c;
}

.status_table td {
  background: white;
}

.status_table th {
  font-size: 11px;
  color: white;
}

.status_table tr {
  padding: 20px;
}

/*.bg-table{
          background: #efefef4f;
    }*/
.op_p {
  border-radius: 3px;
  text-align: center;
  background: #ffc10717;
  /*background: #f30079;*/
  color: #f6a780;
  font-size: 11px;
  font-weight: 700;
  padding: 3px 8px;
}

.op_r {
  border-radius: 3px;
  text-align: center;
  background: #ff00001c;
  color: #c32222;
  font-size: 11px;
  font-weight: 700;
  /* padding: 3px 8px; */
}

.op_a {
  border-radius: 3px;
  text-align: center;
  background: #007bff1c;
  color: #007bff;
  font-size: 11px;
  font-weight: 700;
  padding: 3px 8px;
}

.op_al {
  border-radius: 3px;
  text-align: center;
  background: #acacf3;
  color: #4646d1;
  font-size: 11px;
  font-weight: 700;
  padding: 3px 8px;
}

.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 0px;
}

.arrows path {
  stroke: #fff;
  fill: transparent;
  stroke-width: 2px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

/*@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}*/

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}

/* 4 */

.status_leave {
  margin-top: 12px;
}

.status_leave ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.status_leave ul li {
  display: inline-block;
  font-size: 12px;
  padding: 10px 42px 10px 5px;
}

.status_span {
  background: red;
  color: #ffffff;
  text-align: center;
  font-size: 0.625rem;
  line-height: 1.5;
  /*position: absolute;*/
  width: 10px;
  height: 10px;
  cursor: none;
}

.status1 {
  display: flex;
  align-items: center;
  font-size: 1em;
  /*color: #2e7d32;*/
  /* width: 70px; */
}

.status1 .icon.approved {
  background: #007bff;
}

.status1 .icon.rejected {
  background: #c32222;
}

.status1 .icon.rejected-al {
  background: #4646d1;
}

.status1 .icon.pending {
  background: #f6a780;
}

.status1 .icon {
  background: #2e7d32;
  margin-right: 5px;
  width: 8px;
  height: 8px;
}

@media (max-width: 480px) {
  .navbar .navbar-brand-wrapper {
    width: 75px;
  }
}

@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper {
    justify-content: flex-end;
  }
  .nav-item .arrow-btn {
    width: 40px;
    padding: 5px 0;
    height: auto;
  }
  .brand-logo-image {
    height: 65% !important;
  }
}


@media screen and (max-width: 991px) {
  .toggle-sidebar {
    right: 0px !important;
  }
}
