.hrms_form_cont {
  background: #fff;
  padding: 30px;
  width: 75%;
  box-shadow: 1px 1px 6px #006d9029;
  height: -webkit-fill-available;
}
.hrms_right_lform_sd {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.hrms_right_lform_sd .second,
.hrms_right_lform_sd .third {
  color: white;
  background: #0095a9;
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.hrms_right_lform_sd .hrms_form_cont {
  position: relative;
  z-index: 2;
}
.hrms_right_lform_sd .second {
  position: absolute;
  top: -0.8em;
  left: 4em;
  padding: 4.5em;
  border-radius: 15px;
}
.hrms_right_lform_sd .third {
  position: absolute;
  bottom: -0.8em;
  right: 4em;
  padding: 4.5em;
  border-radius: 15px;
}

.hrms_right_lform_sd .hrms_form_ttl h2 {
  font-weight: 700 !important;
  color: #006d7c !important;
  margin-bottom: 40px !important;
  font-size: 28px !important;
  font-family: system-ui;
}
.hrms_form_box label {
  font-size: 14px;
  font-weight: 700;
  color: #000000d9;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .hrms_form_cont {
    width: 90%;
  }
  .hrms_right_lform_sd .second {
    position: absolute;
    top: -0.8em;
    left: 22px;
    padding: 4.5em;
    border-radius: 15px;
  }
  .hrms_right_lform_sd .third {
    position: absolute;
    bottom: -0.8em;
    right: 22px;
    padding: 4.5em;
    border-radius: 15px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .hrms_form_cont {
    width: 100%;
  }
  .hrms_right_lform_sd .second {
    position: absolute;
    top: -0.8em;
    left: -3px;
    padding: 4.5em;
    border-radius: 15px;
  }
  .hrms_right_lform_sd .third {
    position: absolute;
    bottom: -0.8em;
    right: -3px;
    padding: 4.5em;
    border-radius: 15px;
  }
}
@media (min-width: 450px) and (max-width: 768px) {
  .hrms_form_cont {
    width: 90%;
  }
  .hrms_right_lform_sd .second {
    position: absolute;
    top: -0.8em;
    left: 22px;
    padding: 4.5em;
    border-radius: 15px;
  }
  .hrms_right_lform_sd .third {
    position: absolute;
    bottom: -0.8em;
    right: 22px;
    padding: 4.5em;
    border-radius: 15px;
  }
}
@media screen and (max-width: 450px) {
  .hrms_form_cont {
    width: 90%;
  }
  .hrms_right_lform_sd .second {
    display: none;
  }
  .hrms_right_lform_sd .third {
    display: none;
  }
}

.material-ui-button{
  background-color: #006d7c !important;
}
.hrms_form_ttl {
  text-align: center;
}

.hrms_icon {
  text-align: center;
}

.hrms_form_ttl h2 {
  font-weight: 700;
  color: #282828;
  font-size: 24px;
  font-family: system-ui;
}
.hrms_form_ttl p {
  color: #808080c9;
  font-size: 12px;
}

.login-container{
    text-align: center;
  }

.hr-dashboard-container{
  text-align: left;
}