.brand-logo-image{
    width: auto;
    height: 85%;
    margin-top: 5px;
}

.nav-item.menu-items:hover{
    cursor: pointer;
    /* background-color: rgb(175, 175, 243); */
    background-color: var(--bg-color);
} 

.menu-sidebar{
    position: fixed;
    width: 244px;
}

.menu-sidebar li{
    margin-top: 5px;
    border-radius: 20px;
}


.sidebar .nav .nav-item.active > .nav-link:before {
    content: none;
}

.sidebar .nav .nav-item.active > .nav-link{
    border-right: none;
}


.settings-fixed{
    position: fixed;
    width: 244px;
    bottom:0;
}