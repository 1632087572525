.al_holi{
    text-align: left;
    
}

.timesheet-comments{
    padding: 0px !important;
}


.show-filter-timesheet.show-filter{
    display: none;
}

.material-ui-row{
    display: flex;
    /* justify-content: space-between; */
    gap: 10px;
    /* align-items: flex-end; */
}
.tabs-container {
    display: flex;
    justify-content: space-around;
    background-color: #f4f4f4; /* Light background for the tab bar */
   
  }
  
.tab {
    flex: 1;
    text-align: center;
    padding: 15px 0;
    font-size: 16px;
    text-decoration: none;
    color: #333;
    border-radius: 5px;
  }
  
.tab:hover {
    background-color: #f2f3f3; /* Slight hover effect */
  }
  
.tab.active {
    color: rgb(58, 21, 223);
    border-bottom: 2px solid #007bff; /* Highlight bar at the bottom */
  }
  
