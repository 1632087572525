.visible-div{
    /* transition: width 2.25s ease, margin 2.25s ease; */
  /* width: 100%; */
  /* min-height: calc(100vh - 70px); */
  /* padding: 70px 10px 10px 10px; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column; 
  /* border: 1px solid black; */
}
  @media (max-width: 991px) {
    .main-panel {
      margin-left: 0;
      width: 100%; }
}

.color-palette {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 15px;
}


/* .color-option {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 50%;
} */


/* .color-option {
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
  border: 1px solid #000;
} */
/* border-radius: 50%; */
.color-option {
  width: 70px;
  height: 70px;
  position: relative;
  background: #d3d3d34f;
  cursor: pointer;
  border: 0.5px solid grey;
  border-radius: 15%;
}
.half-circle, .quarter-circle {
  position: absolute;
  width: 40%;
  height: 40%;
}

.upper-half {
  top: 10%;
  left: 10%;
  width: 80%;
  /* height: 50%; */
  border-radius: 50% 50% 0 0;
}

.bottom-left {
  bottom: 10%;
  left: 10%;
  border-radius: 0 0 0 50%;
}

.bottom-right {
  bottom: 10%;
  right: 10%;
  border-radius: 0 0 50% 0;
}

.row-1 .column-1 .upper-half{
  background-color: #D3E3FD;
}
.row-1 .column-1 .bottom-left{
  background-color: #0B57D0
}
.row-1 .column-1 .bottom-right{
  background-color: #e0eaff72;
}

.row-1 .column-2 .upper-half{
  background-color: #bbf1a9;
}
.row-1 .column-2 .bottom-left{
  background-color: #3b6930
}
.row-1 .column-2 .bottom-right{
  background-color: #a0d49033;
}


.row-1 .column-3 .upper-half{
  background-color: #fce27c;
}
.row-1 .column-3 .bottom-left{
  background-color: #6f5d00
}
.row-1 .column-3 .bottom-right{
  background-color: #dec66333;
}


.row-1 .column-4 .upper-half{
  background-color: #fbdccc;
}
.row-1 .column-4 .bottom-left{
  background-color: #705a4d
}
.row-1 .column-4 .bottom-right{
  background-color: #dec1b133;
}





.row-2 .column-1 .upper-half{
  background-color: #dae6d3;
}
.row-2 .column-1 .bottom-left{
  background-color: #566253
}
.row-2 .column-1 .bottom-right{
  background-color: #becab833;
}

.row-2 .column-2 .upper-half{
  background-color: #ebdcff;
}
.row-2 .column-2 .bottom-left{
  background-color: #6a5294
}
.row-2 .column-2 .bottom-right{
  background-color: #d4bbff33;
}


.row-2 .column-3 .upper-half{
  background-color: #d4e6e9;
}
.row-2 .column-3 .bottom-left{
  background-color: #516164
}
.row-2 .column-3 .bottom-right{
  background-color: #b8cacd33;
}


.row-2 .column-4 .upper-half{
  background-color: #ffd9df;
}
.row-2 .column-4 .bottom-left{
  background-color: #924759
}
.row-2 .column-4 .bottom-right{
  background-color: #ffb1c033
}


/* App.css */
.customize-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.customize-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.customize-exit {
  opacity: 1;
  transform: translateY(0);
}

.customize-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

.MuiColorInput-TextField	{
  visibility: none;
}