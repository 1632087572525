.ep_calender {
  /* margin-top: 10px; */
  /* width: 280px; */
  height: auto;
  color: #444;
  padding-left: 10px;
  padding-right: 10px;
  /* border: 1px solid #bbb; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
/* .cal_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.cal_header_text {
  font-size: 14px;
}
.cal_header_link,
.cal_foot_link {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 153, 255);
  text-decoration: underline;
}
.cal_header_link {
  font-size: 16px;
}
.cal_body {
  width: 103%;
  height: 100%;
  padding-bottom: 5px !important;
  display: flex;
  justify-content: center;
}

.cal_footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cal_foot_left,
.cal_foot_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
}
.cal_event {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.cal_circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: chocolate;
}
.cal_foot_text {
  font-size: 14px;
  font-weight: bold;
}
/* .cal_foot_link{
      text-decoration: underline;
  } */

.att-calender-home {
  width: auto !important;
}

.sc-eqUAAy {
  margin: auto;
}

/* Custom styles for the calendar */
.custom-calendar {
  max-width: 500px; /* Example: Set maximum width */
  margin: 0 auto; /* Example: Center the calendar */
  padding: 0px 5px;
  width: 100%;
}
.react-calendar {
  border-radius:5px ;
  width: 95%;
  padding: 0px 10px;
  border: none;
  /* box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2); */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.react-calendar__month-view__weekdays abbr[title] {
  text-decoration: none !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus-visible {
  background-color:  var(--text-color);
  color: white;
}
.react-calendar__navigation button:disabled {
  background-color: var(--text-color);
  color: white;
}
/* Custom styles for specific date statuses */
/* .react-calendar__month-view__weekdays__weekday, .react-calendar__month-view__days__day{
  width: 70px !important;
} */
.react-calendar__tile {
  /* margin: 5px !important; */
  overflow: visible;
  background-color: #fff !important;
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style the text inside the tiles */
.react-calendar__tile abbr {
  display: inline-block;
  border-radius: 50%;
  color: black;
  width: 80%;
  height: 100%;
  text-align: center;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: 
  #ccc;
}

/* Styling for holiday dates */
.absent abbr {
  background-color: #f26a77 !important;
  border-radius: 50% !important;
}

/* Styling for present dates */
.present abbr {
  background-color: #c2e8bf !important;
  border-radius: 50% !important;
}
/* Styling for applied dates */
/* .applied abbr {
  background-color: #fccf49 !important;
  border-radius: 50%;
} */
/* Styling for approved dates */
/* .approved abbr {
  background-color: #15e6a7 !important;
  border-radius: 50%;
} */
/* Styling for rejected dates */
/* .rejected abbr {
  background-color: #FD7E14 !important;
  border-radius: 50%;
} */
/* Styling for rejected dates */
/* .autoleave abbr {
  background-color: #b594f1 !important;
  border-radius: 50%;
} */


/* Styling for holiday dates */
.holiday-public abbr {
  background-color: #eed461 !important;
  border-radius: 50%;
}
.holiday-rh abbr {
  background-color:#eed461 !important;
  border-radius: 50%;
}

.autoleavereject abbr {
  background-color: #73d677 !important;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  background-image: linear-gradient(0deg, transparent 45%, black 45%, black 60%, transparent 60%);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

/* css customiztion for tooltip */

button.present:hover::before,
button.absent:hover::before,
button.applied:hover::before,
button.approved:hover::before,
button.rejected:hover::before,
button.autoleave:hover::before,
button.autoleavereject:hover::before,
button.holiday-public:hover::before,
button.holiday-rh:hover::before {
  width: 47px;
  height: auto;
  border-radius: 5px;
  overflow: visible !important;
  text-align: center !important;
  position: absolute;
  /* top: -10px;
  left: 0; */
  padding: 1px;
  font-size: 10px !important;
  border: 1px solid;
  color: black;
  z-index: 10;
}

button.present:hover::before {
  content: "Present";
  background-color: #c2e8bf !important;
  border-color: #c2e8bf;
}
button.absent:hover::before {
  content: "Absent";
  background-color: #f26a77 !important;
  border-color: #f26a77;
}
/* button.applied:hover::before {
  content: "Applied";
  background-color: #FFC107 !important;
  border-color: #FFC107;
} */
/* button.approved:hover::before {
  content: "Approved";
  background-color: #15e6a7 !important;
  border-color: #15e6a7; */
  /* left: -1px !important;
  font-size: 8.5px !important; */
/* } */
/* button.rejected:hover::before {
  content: "Rejected";
  background-color: #FD7E14 !important;
  border-color: #FD7E14;
} */
button.autoleave:hover::before {
  content: "Autoleave";
  background-color: #b594f1 !important;
  border-color: #b594f1;
  /* left: -1px !important; */
  /* font-size: 8px !important; */
}
button.autoleavereject:hover::before{
  content: "Present";
  background-color: #73d677 !important;
  border-color: #73d677;
}
button.holiday-public:hover::before {
  content: "Holiday";
  background-color: #eed461 !important;
  border-color: #eed461;
}
button.holiday-rh:hover::before{
  content: "RH";
  background-color: #eed461 !important; 
  border-color: #eed461;
}

/* .react-calendar__tile--now.today-leave abbr{ */
  /* background: transparent !important; */
  /* border: 2px dashed red !important; */
/* } */

/* 
.react-calendar__navigation__prev-button {
  order: 2;
}

.react-calendar__navigation__label {
  order: 1;
}

.react-calendar__navigation__next-button {
  order: 3;
} */

