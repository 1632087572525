.status_table thead tr{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }


  .status_table{
    overflow: auto;
    max-height: 288px;
  }
  .raise-request-modal .modal-content {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .raise-request-form {
    display: flex;
    justify-content: space-between;
  }
  
  .raise-request-form input, .raise-request-form select ,.raise-request-form textarea{
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    transition: border-color 0.3s;
  }
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    transition: border-color 0.3s;
    resize: vertical; /* Allow both horizontal and vertical resizing */
    box-sizing: border-box;
  }
  
  textarea:focus {
    border-color: #007bff;
    outline: none;
  }

  .raise-request-form input:focus, .raise-request-form select:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .form-left, .form-right {
    width: 48%;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: 600;
    color: #333;
    margin-bottom: 8px;
  }

  input, select, textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 10px;
    color: #333;
    border: 1px solid #80808073;
    box-sizing: border-box;
  }
  
  input[readonly],textarea[readonly]{
    background-color: #f9f9f9;
  }
  
  button {
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    transition: background-color 0.3s, color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
    color: #fff;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
  
  .modal-header {
    border-bottom: none;
  }
  
  .modal-title {
    font-weight: 600;
    color: #007bff;
  }
  button:hover {
    background-color: #e0e5eb;
    color: #645ff1;
  }
  
  button.btn-primary {
    background-color: #007bff;
    border: none;
  }
  
  button.btn-secondary {
    background-color: #6c757d;
    border: none;
  }
  
.leave-info-modal .modal-content {
  width: 125% ;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  left: 50%;
  transform: translateX(-50%);
}

