.hrms-profile {
  display: flex;
  align-items: center;
}

.hrms-profile {
}

.back-icon i {
  background: #e5e4e4;
  padding: 6px 9px;
  width: 28px;
  height: 28px;
  border-radius: 50px;
}

.hrms-profile-avtr {
  /*background: red;*/
  padding: 10px;
  width: 9%;
  overflow: hidden;
}

.hrms-profile-avtr img {
  background: red;
  width: 70px;
  border-radius: 53px;
}

.hrms-profile-name {
}

.hrmsp-name {
  font-weight: 600;
  font-size: 20px;
}

.hrms-badge {
  font-size: 11px;
  background: #c6f0f563;
  padding: 4px;
  border-radius: 5px;
  margin-left: 4px;
  color: #04636ed9;
}

.profile-details {
  background: #067fc90d;
}

.avtr-info {
  background: white;
  box-shadow: 0px 0px 4px #00000014;
  padding: 24px 0;
  margin-bottom: 20px;
}

.avtr-gen {
  border-bottom: 2px solid #6c6f7033;
  padding: 0 18px;
  text-align: left;
}

.avtr-gen h2 {
  font-size: 18px;
  font-weight: 600;
  /* color: #0dabbf; */
}
.avtr-editt h3 {
  /* color: #043b93; */
}
.avtr-per {
  padding: 14px 18px;
}

.avtr-per h3 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 14px;
}

.avtr-edit {
  font-size: 12px;
  color: #043b93;
  font-weight: 600;
}

.avtr-hr {
  margin: 0 20px;
  border-bottom: 2px solid #6c6f7033;
}

.avtr-table,
.avtr-table tr,
.avtr-table td {
  border: none;
  font-size: 13px;
}

/* .avtr-info .avtr-gen {
    display: inline-block;
    margin: 0;
    padding: 10px;
    position: relative;
  }
  
  .avtr-info.active .avtr-gen ::after {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    background-color: #007bff; 
    position: absolute;
    right: 0;
    top: 0;
  }
   */

.myprofile-sidebar {
  position: fixed;
  width: 20%;
  right: 0;
}


.address-form {
    display: grid;
    grid-template-columns: 1fr; /* Single column layout */
    gap: 10px; /* Add some gap between rows */
  }
  
  .address-form div {
    display: flex;
  }
  
  .address-form .city-state-zip {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  
  .address-form input,
  .address-form select {
    width: 100%;
    padding: 8px;
  }
  



  
  .img-container {
    position: relative;
    display: inline-block;
  }
  
  /* .img-container img {
    width: 150px; 
    height: 150px;
    border-radius: 50%;
  } */
  
  .edit-icon {
    position: absolute;
    bottom: 8px;
    height: 2rem;
    width: 2rem;
    right: 22px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px;
    /* border-radius: 50%; */
    cursor: pointer;
    display: none;
  }
  
  .img-container:hover .edit-icon {
    display: block;
  }
  
  .img-container .edit-icon input[type="file"] {
    display: none;
  }
  

  @media (max-width: 768px) {
    .myprofile-sidebar {
      display: none;
    }
  }
  
  @media (max-width: 991px) {
    .myprofile-sidebar {
      width: 25%;
    }
  }