.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  /* background: bisque; */
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  text-align: center;
  /* background: bisque; */
  inset: 55px 0px auto auto !important;
}

.htd {
  width: 0%;
  padding: 2px 15px 8px 0;
  min-width: 150px;
  color: #212529;
}

.htd + td {
  padding: 0px 36px 0px 8px;
}

.divhtd {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: auto !important;
  align-items: baseline;
  min-width: 50px;
  margin: 0 -2.5px;
}

.htd {
  text-align: left;
}

.htds {
  padding-right: 5px;
}

.tb-view input[type="text"],
.tb-view input[type="password"],
.tb-view input[type="date"],
.tb-view input[type="number"],
.tb-view textarea,
.tb-view select {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
}

[type="text"],
[type="date"],
[type="password"],
[type="number"],
textarea,
select {
  color: #484949;
  width: 100%;
}

.modal-footer {
  margin-top: 1rem;
  border-top: 1px solid #ccc !important;
}
.modal-footer .btn {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 0;
  border-color: #ccc;
}
tr[class="td_leave"] > td {
  border: none;
}

.modal-content {
  border: 1px solid #ccc !important;
}
.modal-header,
.modal-footer {
  border-bottom: 1px solid #ccc !important;
}

.tb-view {
  border: none;
}

tr[class="td_leave"] {
  height: 40px;
}

.modal-body {
  height: max-content !important;
}

.leave_application {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}



/* CSS customiztion for Assets Modal */
.modal-title{
  font-size: 1.25rem;
}
.assetsModalForm select {
  width: 90%;
  padding: 6px 10px;
  color: grey;
  border: none;
  outline: none;
  font-size: 14px;
  background-color: #f3f4f6;
}
.assetsModalForm input[type="text"],
[type="email"] {
  border: none;
  outline: none;
  /* width: 70%; */
  /*margin: 8px 0;*/
  padding: 6px 10px;
  font-size: 14px;
  background-color: #f3f4f6;
}
.assetsModalForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.assetsModalForm tr {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.assetsModalForm td {
  width: 250px;
  display: flex;
  padding: 5px 15px !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: none !important;
}
.assetsModalForm label {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 4px;
}

.assetsForm .modal-footer {
  margin-top: 3rem;
}
.assetsForm .modal-footer .btn {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 0;
}
.assetsForm .modal-footer .btn-primary {
  background: #ed7d2d;
  border: 1px solid;
}

/* CSS customization for asset modal drag and drop file feature */
.tb-drag-file {
  border: 2px dashed #c9c9c9;
  height: 100px;
  width: 710px;
}

.formbold-pt-3 {
  padding-top: 12px;
}
.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
}

.formbold-form-wrapper {
  margin: 0 auto;
  max-width: 550px;
  width: 100%;
  background: white;
}
.formbold-form-label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  margin-bottom: 12px;
}
.formbold-form-label-2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.formbold-form-input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: white;
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  outline: none;
  resize: none;
}
.formbold-form-input:focus {
  border-color: #6a64f1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-btn {
  text-align: center;
  font-size: 16px;
  border-radius: 6px;
  padding: 14px 32px;
  border: none;
  font-weight: 600;
  background-color: #6a64f1;
  color: white;
  cursor: pointer;
}
.formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold--mx-3 {
  margin-left: -12px;
  margin-right: -12px;
}
.formbold-px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}

div.formbold-file-input {
  position: relative;
  width: 100%;
}

div.formbold-file-input input {
  /* opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%; */
  display: none;
}

.formbold-file-input label {
  position: relative;
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  min-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 48px;
  text-align: center;
  background-color: #f3f4f694;
}
.formbold-drop-file {
  display: block;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  margin-bottom: 8px;
}

.formbold-or {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  display: block;
}
.formbold-browse {
  font-weight: 500;
  font-size: 16px;
  color: #0cafb1;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 4px;
}
.assetsForm .modal-footer{
  margin-top: 0px !important;
  border-top: none !important;
}

.btn:focus{
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: rgb(175, 175, 243);
  border-radius: 20px;
}