
html {
    height: 100%
}

body {
    font-family: "Poppins","Times New Roman", Times, serif;
    font-weight: 400;
    font-style: normal;
}

.dash-count {
    display: flex;
    justify-content: space-between;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    margin: 0 0 10px;
    padding: 20px;
}

.dash-count.das {
    background: linear-gradient(135deg, #8e4cf1 0, #c554bc 100%);
    background: #4fab52;
}

.dash-count.das1 {
    background: linear-gradient(135deg, #f75050 0, #c80303 100%);
    background: #ff2727;
}

.dash-count.das2 {
    background: linear-gradient(135deg, #23bdb8 0, #65a986 100%);
    background: #eb6f33;
}

.dash-count.das3 {
    background: linear-gradient(135deg, #289cf5, #4f8bb7);
    background: #8760fb;
}

.dash-title {
    font-weight: 600;
    margin-bottom: 0;
    line-height: 38px;
}

.dash-cunt {
    font-weight: 600;
    font-size: 27px;
    margin-bottom: 0;
    /* background: white; */
    /* color: red; */
    /* border-radius: 30px;
    padding: 7px 10px 10px 7px;
    width: 50px;
    height: 50px; */
}
.dash-imgs{
    text-align: center;
}
/* .dac {
    color: #4fab52;
}

.dac1 {
    color: #ff2727;
}

.dac2 {
    color: #eb6f33;
}

.dac3 {
    color: #8760fb;
} */

.das-filter {
    /* display: flex; */
    margin-bottom: 10px;
    padding: 18px;
}

.das-filtitle {
    display: flex;
    gap: 30px;
    /* color: #008cee; */
    font-size: 18px;
    padding-right: 24px;
}

.das-filtitle select,
.das-filtitle input,
.das-filtitle button{
    border-radius: 5px;
    border: 1px solid #8080801f;
    /* background: #8080801c; */
    margin-right: 7px;
    padding: 0px 10px;
    width: auto;
}
.das-filtitle button{
    background: #0d94f3d6;
    color: white;
}

.das-lastm {
    background: #008cee14;
    padding: 5px 14px;
    border-radius: 30px;
    color: #008cee;
}

.btn-tbl-edit {
    border-radius: 30px;
    background-color: #4fab52;
    color: #fff;
    font-size: 15px;
    padding: 1px 10px 3px 10px;
}

.atable {
    /* background: white;
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35); */
    overflow-x: auto;
    padding: 15px;
}

.basic-table {
    width: 100%;
    /* border: 1px solid #eee; */
}

.basic-table th {
    font-size: 16px;
    /* color: #1B2559;   */
}

.basic-table tr {
    /* border: 1px solid #eee; */
}

.basic-table tr:hover {
    /* background: #ececec */
}

.basic-table th,
td {
    /* padding: 0.675rem; */
    /* vertical-align: top; */
}
.multi-table{
    padding: 0px !important;
}

.select-filter{
    width: fit-content;
}


/* .dash-count:hover{
    width: 105%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
} */

.box {
    border-radius: 6px;
    background: transparent;
}
.box.active {
    width: 100%;
    height:103%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.das-filtitle .attend-svg {
    position: relative;
    height: 55px;
    width: 30px;
    left: 2px;
    top: -30px;
}



.das-filtitle .attend-svg path{
    stroke: #2c2222;
    fill: transparent;
    stroke-width: 2px;
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite
}


.das-filtitle.show-filter{
    display: none;
}

.showFilter-btn{
    cursor: pointer;
    background-color: #80808024;
    border-radius: 30px;
    display: flex;
    gap: 10px;
    width: 88px;
    height: 30px;
}

/* .showFilter-btn:hover{
    background-color: #289cf5;
    border-radius: 12px;
} */


.das-filtitle label{
    margin-bottom: 0px;
}

.tag-Weekend{
    border-radius: 30px;
    background-color: lightgray;
    /* color: #fff; */
    font-size: 15px;
    padding: 1px 10px 3px 10px;
}

.tag-Leave{
    border-radius: 30px;
    background-color: rgba(36, 141, 228, 0.767) ;
    color: white;
    font-size: 15px;
    padding: 1px 10px 3px 10px;
}

.tag-leave-unapporved{
    border-radius: 30px;
    background-color: #ff000030;
    color: #ff2727;
    font-size: 15px;
    padding: 1px 10px 3px 10px;
}

.tag-AutoLeave{
    border-radius: 30px;
    background-color: rgba(115, 115, 230, 0.767) ;
    /* color: ; */
    color: white;
    font-size: 15px;
    padding: 1px 10px 3px 10px;
}

.tag-Present{
    border-radius: 30px;
    background-color: #4fab52;
    color: #fff;
    font-size: 15px;
    padding: 1px 10px 3px 10px;
}

.tag-Absent{
    border-radius: 30px;
    background-color: #ff000030;
    color: #ff2727;
    font-size: 15px;
    padding: 1px 10px 3px 10px;
}

.tag-Holiday{
    border-radius: 30px;
    background-color: #faa02b;
    color: #FFF;
    font-size: 15px;
    padding: 1px 10px 3px 10px;
}

.attendanceDataTableHead tr.allTypeData th{

    background-color: #01b8ff;
    border: 1px solid #01b8ff;
    
    color: white;
}
.attendanceDataTableHead tr.allTypeData th:hover{
    /* background-color: #14b49c !important; */
    color: #1b2559;
}
.attendanceDataTableHead tr.presentTypeData th{
    background-color: #4fab52;
    border: 1px solid #4fab52;
    color: #fff !important;
}
.attendanceDataTableHead tr.presentTypeData th:hover{
    background-color: #4fab52 !important;
    color: #fff !important;
}
.attendanceDataTableHead tr.absentTypeData th{
    background-color: #ff2727;
    border: 1px solid #ff2727;
    color: #fff !important;
}
.attendanceDataTableHead tr.absentTypeData th:hover{
    background-color: #ff2727 !important;
    color: #fff !important;
}
.attendanceDataTableHead tr.leaveTypeData th{
    background-color: #eb6f33;
    border: 1px solid #eb6f33;
    color: #fff !important;
}
.attendanceDataTableHead tr.leaveTypeData th:hover{
    background-color: #eb6f33 !important;
    color: #fff !important;
}
.attendanceDataTableHead tr.autoLeaveTypeData th{
    background-color: #8760fb;
    border: 1px solid #8760fb;
    color: #fff !important;
}
.attendanceDataTableHead tr.autoLeaveTypeData th:hover{
    background-color: #8760fb !important;
    color: #fff !important;
}

tbody tr.allTypeData td{
    border: 1px solid #01b8ff;
}
tbody tr.presentTypeData td{
    border: 1px solid #14b49c;
}
tbody tr.absentTypeData td{
    border: 1px solid #ff2727;
}
tbody tr.leaveTypeData td{
    border: 1px solid #eb6f33;
}
tbody tr.autoLeaveTypeData td{
    border: 1px solid #8760fb;
}
.backbutton{
    font-size: xx-large;
    margin-right: 5px;
    color: #4b4a4a;
    cursor: pointer;
}
.backbutton:hover{
    color: #000;
}


.content-wrapper {
    /* background: #eff8ff; */
    padding: 1.3rem 1.75rem;
}


.dash-count:hover{
    cursor: pointer;
}

.basic-table.table-mini{
    width: 60%;    
    margin: auto;
}

.attendance-tags {
    text-align: center;
}

.all-tables-head th{
    color: white;
}


.radio-option {
    display: flex;
    align-items: center;
  }