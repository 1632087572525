.al_holi .al_holi_tl {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 4px;
}

.al_holi .al_holi_stl {
  font-size: 14px;
  color: #5e5d5d;
}

.ev-section {
  margin: 25px 0;
}

.ev_head {
  display: flex;
  background: white;
  padding: 18px;
  justify-content: space-between;
}

.ev_tile {
  color: #008cee;
  font-weight: 600;
  font-size: 18px;
  padding-right: 24px;
}

.ev_tile img {
  height: 12px;
  margin-top: -4px;
}

.ev_tile .ev-icon {
  padding-right: 5px;
}

.ev_brcrm {
  background: #008cee14;
  padding: 5px 8px;
  border-radius: 5px;
  color: #008cee;
  font-size: 12px;
}

.ev_ricon {
}

.ev_table {
  overflow-x: auto;
}
.ev-section {
  margin: 25px 0;
  padding-bottom: 20px;
  background: white;
  box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, 0.35);
}
.ev_body {
  padding: 2px 15px;
}

#calendar {
  width: 100%;
}

#calendar tr,
#calendar tbody {
  width: 100%;
}

#calendar .weekdays {
  color: black;
}

#calendar th.active {
  color: #2196f3;
}

#calendar .weekdays th {
  text-align: center;
  text-transform: uppercase;
  line-height: 20px;
  border: none !important;
  padding: 10px 6px;
  color: #000;
  font-size: 13px;
}

#calendar .other-month {
  color: #666;
}

#calendar td {
  text-align: center;
  height: 110px;
}

@media screen and (max-width: 768px) {
  #calendar td {
    height: auto;
  }
}

#calendar td,
#calendar th {
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #eee;
}

.ev_status {
  margin-top: 12px;
}

.ev_status ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.ev_status ul li {
  display: inline-block;
  font-size: 12px;
  padding: 10px 42px 10px 5px;
}

.ev_st1 {
  display: flex;
  align-items: center;
  font-size: 15px;
  width: 70px;
}

.ev_st_icon {
  padding-right: 6px;
}

@media screen and (max-width: 768px) {
  .ev_status ul li {
    padding: 10px 15px 10px 5px;
  }
}

.ev_divide {
  display: flex;
}
.ev_divleft {
  width: 65%;
}
.ev_divright {
  width: 35%;
  margin-left: 18px;
}
.ev_emp_info {
  display: flex;
  margin-bottom: 20px;
}
.ev_emp_infod {
  width: 25%;
  border: 1px solid black;
  padding: 0 10px 6px;
  margin: 0 10px;
  border-radius: 7px;
  background: #ff00000a;
}
.ev_emp_infod:first-child {
  margin-left: 8px;
  border: 1px solid #ff980075;
  background: #ff98000f;
}
.ev_emp_infod:nth-child(2) {
  border: 1px solid #6221e747;
  background: #6221e717;
}
.ev_emp_infod:nth-child(3) {
  border: 1px solid #2196f375;
  background: #2196f312;
}
.ev_emp_infod:last-child {
  margin-right: 0px;
  border: 1px solid #e91e6361;
  background: #e91e6314;
}
.ev_emp_des {
  font-size: 13px;
  color: #323232;
  margin-bottom: 0px;
}

.ev_hr_tbl {
  border: 1px solid #00000012;
  width: 100%;
}
.ev_hr_tbl td,
th {
  padding: 13px;
  font-size: 14px;
}
.ev_hr_bg {
  background: #afafaf12;
}
.ev_hrt_box {
  padding-left: 8px;
}
@media screen and (max-width: 768px) {
  .ev_divide {
    display: block;
  }
  .ev_divleft {
    width: 100%;
  }
  .ev_divright {
    width: 100%;
  }
  .ev_emp_infod:first-child {
    margin-left: 0px;
  }
  .ev_hrt_box {
    padding-left: 0px;
    overflow-x: auto;
  }
}

.evProcess {
  padding: 0 10px;
  border-left: 1px solid #dfdfdf;
  /* height: 100%; */
  max-height: 283px;
  overflow: auto;
}

.evProcess_head {
  margin-bottom: 15px;
  color: #008cee;
  font-weight: 600;
  font-size: 15px;
}

.evProcess_steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
}

.evProcess_step {
  display: flex;
}

.evProcess_step img {
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.evProcess_step p {
  margin-bottom: 0;
  font-size: 12px;
  color: gray;
}

.evProcess_tag .span1 {
  background: #9c27b00d;
  font-size: 11px;
  border-radius: 55px;
  padding: 2px 6px;
  color: #bf13dd;
}

.evProcess_tag .span2 {
  background: #2196f314;
  font-size: 11px;
  border-radius: 55px;
  padding: 2px 6px;
  color: #2196f3;
}
@media screen and (max-width: 768px) {
  .ev_divright {
    margin-left: 0px;
  }
  .evProcess {
    border: none;
    margin: 16px 0;
  }
}

.ev_request {
  display: flex;
}
.evr_left {
  width: 70%;
  margin-right: 10px;
}
.evr_right {
  width: 30%;
  margin-left: 10px;
}
.evr-section {
  padding-bottom: 20px;
  background: white;
  height: 100%;
  box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, 0.35);
}
.ev_approve {
  display: flex;
}
.ev_approveL {
  width: 50%;
}
.ev_approveR {
  width: 50%;
  padding-left: 15px;
}
.ev_ProList {
  display: flex;
  margin: 12px 0;
}
.ev_ProList:first-child {
  margin-top: 0;
}
.ev_ProDetailL img {
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
.ev_ProDetailR p {
  margin-bottom: 0;
  font-size: 12px;
  color: gray;
}
.ev_ProDivider {
  font-size: 15px;
  color: gray;
  padding: 12px 0 0 0;
}


.logo-hr-dashboard{
  position: fixed;
  top: 0;
  width: 244px;
  height: 69px;
  background-color: white;
  font-size: 35px;
  font-weight: 600;
  z-index: 100;
  box-shadow: 20px 19px 34px -15px rgb(0 0 0 / 8%);
}


.atten-table {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0;
}

.atten-table.visible {
  max-height: 250px; 
  opacity: 1;
  overflow: auto;
}