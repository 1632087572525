.main-item {
    padding: 10px;
    background-color: #fff;
    /* width: 700px; */
  }
  
  .background-masker {
    background-color: #fff;
    position: absolute;
  }
  
  .btn-divide-left {
    top: 0;
    left: 25%;
    height: 100%;
    width: 5%;
  }
  
  @keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }
  
  .animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 70px;
    position: relative;
  }
  
  .static-background {
    background-color: #f6f7f8;
    background-size: 800px 104px;
    height: 70px;
    position: relative;
    margin-bottom: 20px;
  }
  
  .shared-dom {
    /* width: 800px; */
    height: 140px;
  }
  .sub-rect {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    float: left;
    margin: 20px 20px 20px 0;
  }
  .pure-background {
    background-color: #eee;
  }
  
  .css-dom:empty {
    width: 280px;
    height: 220px;
    border-radius: 6px;
    box-shadow: 0 10px 45px rgba(0, 0, 0, .2);
    background-repeat: no-repeat;
    
    background-image:
      radial-gradient(circle 16px, lightgray 99%, transparent 0),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(#fff, #fff); 
    
    background-size:
      32px 32px,
      200px 32px,
      180px 32px,
      230px 16px,
      100% 40px,
      280px 100%;
    
    background-position:
      24px 30px,
      66px 30px,
      24px 90px,
      24px 142px,
      0 180px,
      0 0;
  }




  /* table loading css */



  /* .post {
    width: 220px;
    height: 80px; } */
    /* .post .avatar {
      float: left;
      width: 52px;
      height: 52px;
      background-color: #ccc;
      border-radius: 25%;
      margin: 8px;
      background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
      background-size: 600px;
      animation: shine-avatar 1.6s infinite linear; } */
    .post .line {
      float: left;
      width: 100%;
      height: 16px;
      margin-top: 12px;
      border-radius: 7px;
      background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
      background-size: 600px;
      animation: shine-lines 1.6s infinite linear; }
    .post .avatar + .line {
      margin-top: 11px;
      /* width: 100px;  */
    }
    .post .line ~ .line {
      background-color: #ddd; }
  
  @keyframes shine-lines {
    0% {
      background-position: -100px; }
    40%, 100% {
      background-position: 140px; } }
  
  @keyframes shine-avatar {
    0% {
      background-position: -32px; }
    40%, 100% {
      background-position: 208px; } }
  